import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fileContainer" }
const _hoisted_2 = {
  key: 0,
  id: "dplayer"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "buttonGroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.drawerVisibleSync,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerVisibleSync) = $event)),
    closable: false,
    class: "fileDrawer",
    width: "600px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.fileType === 'video')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.fileType === 'img')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.fileUrl
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_button, { onClick: _ctx.goBack }, {
          default: _withCtx(() => [
            _createTextVNode("返回")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: _ctx.downFile
        }, {
          default: _withCtx(() => [
            _createTextVNode("下载")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}