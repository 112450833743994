import { appStore } from '@/store/modules/app';
import { computed } from 'vue';

export function useAppInfo() {
  const ossConfig = computed(() => {
    return appStore.ossConfig;
  });
  return {
    ossConfig,
  };
}
