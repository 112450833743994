
  import { computed, defineComponent } from 'vue';
  import { saveAs } from 'file-saver';
  // import DPlayer from 'dplayer';

  export default defineComponent({
    name: 'FilePreview',
    emits: ['update:visible'],
    props: {
      fileUrl: {
        type: String,
        default: '',
      },
      fileType: {
        type: String,
        default: '',
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const drawerVisibleSync = computed({
        get: () => props.visible,
        set: (value) => {
          emit('update:visible', value);
        },
      });
      return {
        drawerVisibleSync,
      };
    },
    methods: {
      goBack() {
        this.drawerVisibleSync = false;
      },
      downFile() {
        const fileName = this.fileUrl.split('/').pop();
        saveAs(this.fileUrl, fileName);
        this.drawerVisibleSync = false;
      },
    },
    watch: {
      fileType: {
        handler(val) {
          if (val === 'video') {
            // this.$nextTick(() => {
            //   const dp = new DPlayer({
            //     container: document.getElementById('dplayer'),
            //     video: {
            //       url: this.fileUrl,
            //     },
            //   });
            //   return dp;
            // });
          }
        },
        immediate: true,
      },
    },
  });
