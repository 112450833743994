import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b324c08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "we_upload_container" }
const _hoisted_2 = {
  key: 0,
  style: {"margin-bottom":"10px"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_file_preview = _resolveComponent("file-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isArray(_ctx.fileList))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("span", {
                class: "canTouch",
                onClick: ($event: any) => (_ctx.showDrawer(_ctx.popType, index))
              }, _toDisplayString(_ctx.getRealName(item.filename)), 9, _hoisted_3),
              (item.url && _ctx.editMode)
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 0,
                    "icon-name": "CloseCircleFilled",
                    onClick: ($event: any) => (_ctx.deleteFile(index))
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createElementVNode("span", {
              class: "canTouch",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDrawer(_ctx.popType, _ctx.index)))
            }, _toDisplayString(_ctx.getRealName(_ctx.fileList.filename)), 1),
            (_ctx.fileList.url && _ctx.editMode)
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  "icon-name": "CloseCircleFilled",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteFile(-1)))
                }))
              : _createCommentVNode("", true)
          ], true)
        ])),
    _withDirectives(_createElementVNode("span", null, " 无 ", 512), [
      [_vShow, 
        !_ctx.editMode &&
        ((_ctx.fileList instanceof Array && (_ctx.fileList.length === 0 || _ctx.isEmptyData)) ||
          !_ctx.fileList ||
          _ctx.fileList.url === '' ||
          Object.keys(_ctx.fileList).length === 0)
      ]
    ]),
    _createVNode(_component_a_upload, {
      onChange: _ctx.handleChange,
      disabled: _ctx.disabled,
      accept: _ctx.accept,
      showUploadList: _ctx.showUploadList,
      multiple: _ctx.multiple,
      customRequest: _ctx.customRequest,
      "before-upload": _ctx.beforeUpload
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "button", {}, () => [
          _withDirectives(_createVNode(_component_a_button, { type: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode("文件上传")
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.editMode]
          ])
        ], true)
      ]),
      _: 3
    }, 8, ["onChange", "disabled", "accept", "showUploadList", "multiple", "customRequest", "before-upload"]),
    _createVNode(_component_file_preview, {
      visible: _ctx.drawerVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.drawerVisible) = $event)),
      "file-url": _ctx.url,
      "file-type": _ctx.drawerType
    }, null, 8, ["visible", "file-url", "file-type"])
  ]))
}